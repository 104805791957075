















































































import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Vuelidate from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { Dropdown, CustomInput, SvgIcon } from "@/components";
import { Favorite } from "@/types";
import { FavoriteType, AddFavoriteResponse } from "@/proto/favorites/all_pb";

interface Biller {
  label: string;
  value: string;
}

const favoritesModule = namespace("favorites");

Vue.use(Vuelidate);

@Component({
  components: {
    Dropdown,
    CustomInput,
    SvgIcon,
  },
  validations: {
    selectedBiller: {
      required,
    },
    billerAccount: {
      required,
    },
    selectedCategory: {
      required,
    },
  },
})
export default class AddBillerForm extends Vue {
  @favoritesModule.Action("addFavorite")
  addFavorite!: (fave: Favorite) => AddFavoriteResponse.AsObject;

  @Prop()
  private readonly billerList!: Array<Biller>;

  selectedBiller = "";
  selectedCategory = "";
  billerAccount = "";
  loading = false;

  async onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    this.loading = true;

    try {
      await this.addFavorite({
        billingCode: this.selectedBiller,
        refCode: this.billerAccount,
        type: FavoriteType.BILLER,
        category: this.selectedCategory,
      });
      this.$emit("on-add-biller");
    } catch (error) {
      const err = error as Error;
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  get billerCategories() {
    return [
      "Electric Utilities",
      "Water Utilities",
      "Cable and Internet",
      "Telecommunications",
      "Real Estate",
      "Insurance",
      "Travel",
      "Transportation",
      "Healthcare",
      "Load and Credit",
      "Loans",
      "Memorial",
      "Others",
      "School",
    ];
  }
}
