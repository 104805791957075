






































































































import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VModal from "vue-js-modal";

import {
  CustomTable,
  Dropdown,
  SvgIcon,
  CustomInput,
  PayBillModal,
} from "@components";
import CustomRow from "./components/CustomRow.vue";
import AddBillerForm from "./components/AddBillerForm.vue";
import { Biller, PayBill } from "@/types";
import { Favorite } from "@/proto/favorites/all_pb";
import { GetBillInputsResponse, PayBillResponse } from "@/proto/billing/all_pb";

Vue.use(VModal);

const transactionsModule = namespace("transactions");
const favoritesModule = namespace("favorites");

interface BillerInfo {
  id: string;
  billingCode: string;
  company: string;
  account: string;
  category: string;
  actions: string;
}

@Component({
  components: {
    CustomInput,
    SvgIcon,
    Dropdown,
    CustomTable,
    CustomRow,
    AddBillerForm,
    PayBillModal,
  },
})
export default class BillsPaymentList extends Vue {
  @favoritesModule.Getter("favoriteBillers")
  favoriteBillers!: Array<Favorite.AsObject>;
  @favoritesModule.Action("getFavoritesList") getFavoritesList!: () => void;

  @transactionsModule.Action("getBillers") getBillers!: () => Array<Biller>;
  @transactionsModule.Action("payBill")
  payBill!: (payload: PayBill) => PayBillResponse.AsObject;
  @transactionsModule.Action("getBillInputs")
  getBillInputs!: (payload: string) => GetBillInputsResponse.AsObject;

  billers: Array<Biller> = [];
  billInputs: Record<string, any> = {};
  // billerFavorites: Array<BillerInfo> = [];
  selectedBiller: BillerInfo | Record<string, any> = {};

  payBillLoading = false;
  payBillError = "";

  get BILL_COLUMNS() {
    return [
      {
        field: "company",
        label: "BILLING COMPANY",
        sortable: true,
      },
      {
        field: "account",
        label: "ACCOUNT NO",
        sortable: true,
      },
      {
        field: "category",
        label: "CATEGORY",
        sortable: true,
      },
      {
        field: "actions",
        label: "ACTIONS",
        sortable: false,
      },
    ];
  }

  get sortableColumns() {
    return this.BILL_COLUMNS.filter((column) => !!column.sortable);
  }

  get billerList() {
    return this.billers.map((biller) => {
      return {
        label: biller.label,
        value: biller.refCode,
      };
    });
  }

  showAddBillerModal() {
    this.$modal.show("add-bill-modal");
  }

  hideAddBillerModal() {
    this.$modal.hide("add-bill-modal");
  }

  closeSuccessModal() {
    this.$modal.hide("success-add-bill-modal");
  }

  getBillerLabel(code: string) {
    const biller = this.billers.find((biller) => biller.refCode === code);
    return biller ? biller.label : "N/A";
  }

  onAddBiller() {
    this.hideAddBillerModal();
    this.$modal.show("success-add-bill-modal");
    this.getFavoritesList();
  }

  async onPay(row: Record<string, any>) {
    this.selectedBiller = this.billerFavorites.find((biller: BillerInfo) => {
      return biller?.id === row.id;
    }) as BillerInfo;
    // try {
    //   this.billInputs = await this.getBillInputs(
    //     this.selectedBiller.billingCode
    //   );
    //   console.log(this.billInputs);
    // } catch (error) {
    //   console.log(error);
    // }
    this.$modal.show("pay-bill-modal");
  }

  async onPaySubmit(payload: Record<string, any>) {
    this.payBillLoading = true;
    try {
      await this.payBill({
        billerCode: payload.billingCode,
        accountNo: payload.account,
        amount: payload.amount,
      });
    } catch (error) {
      const err = error as Error;
      this.payBillError = err.message;
    } finally {
      this.payBillLoading = false;
    }
  }

  get billerFavorites(): Array<BillerInfo> {
    return this.favoriteBillers.map((fav) => {
      return {
        id: fav.id,
        billingCode: fav.billingcode,
        company: this.getBillerLabel(fav.billingcode),
        account: fav.refcode,
        category: fav.category,
        actions: "",
      };
    });
  }

  async mounted() {
    try {
      this.billers = await this.getBillers();
    } catch (error) {
      console.log(error);
    }
  }
}
