














import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CustomRow extends Vue {
  @Prop()
  field!: string;

  @Prop()
  row!: Record<string, any>;

  get fieldValue() {
    return this.row[this.field];
  }

  onPay() {
    this.$emit("on-pay", this.row);
  }
}
